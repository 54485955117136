import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import SignUpView from "../views/SignUpView";
import SignInView from "@/views/SignInView.vue";
import ForgotPasswordView from "@/views/ForgotPasswordView.vue";
import ProFileView from "@/views/ProFileView.vue";
import DashBoardView from "@/views/DashBoardView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Home",
      requireAuth: true,
    },
  },

  {
    path: "/signup",
    name: "registration",
    component: SignUpView,
  },

  {
    path: "/signin",
    name: "login",
    component: SignInView,
  },

  {
    path: "/forgot-password",
    name: "forgot",
    component: ForgotPasswordView,
  },

  {
    path: "/profile",
    name: "profile",
    component: ProFileView,
    meta: {
      title: "Profile",
      requireAuth: true,
    },
  },

  {
    path: "/dashboard",
    name: "dashboard",
    component: DashBoardView,
    meta: {
      title: "Dashboard",
      requireAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
