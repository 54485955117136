const NotificationService = {
  info(title = "Info") {
    // this.$toastr('info', text, title)
    window.Toast.fire({
      icon: "info",
      title: title,
    });
  },

  success(title = "Success") {
    // this.$toastr('success', text, title)
    window.Toast.fire({
      icon: "success",
      title: title,
    });
  },

  error(title = "Error") {
    // this.$toastr('error', text, title)
    window.Toast.fire({
      icon: "error",
      title: title,
    });
  },
};

export default NotificationService;
