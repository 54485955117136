<template>
  <div class="section">
    <div class="signup-box">
      <form class="signup-form">
        <h2>Registration</h2>

        <div class="signup-inputBox">
          <input v-model="first_name" type="text" required />
          <span>First Name</span>
          <i></i>
        </div>

        <div class="signup-inputBox">
          <input v-model="last_name" type="text" required />
          <span>Last Name</span>
          <i></i>
        </div>

        <div class="signup-inputBox">
          <input v-model="email" type="email" required />
          <span>Email</span>
          <i></i>
        </div>

        <div class="signup-inputBox">
          <input v-model="password" type="password" required />
          <span>Password</span>
          <i></i>
        </div>
        <div class="signup-links">
          <p>Al ready account created?</p>
          <router-link to="/signin"> Sign In</router-link>
        </div>
        <button @click.prevent="registerBtn()">Register</button>
      </form>
    </div>
  </div>
</template>

<script>
import ApiService from "../services/api.service";
import NotificationService from "../services/notifications.service";
export default {
  name: "RegistarPage",
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
    };
  },
  methods: {
    registerBtn() {
      const data = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        password: this.password,
      };
      ApiService.post("/users/", data)
        .then((res) => {
          console.log(res.data);
          NotificationService.success("Registration completed");
          this.$router.push({ name: "login" });
        })
        .catch((err) => {
          NotificationService.error("Try again");
          console.log(err);
        });
      this.email = "";
      this.first_name = "";
      this.last_name = "";
      this.password = "";
    },
  },
};
</script>

<style>
.section {
  max-width: 1250px;
  width: 100%;
  margin: 10rem auto;
}

.signup-box {
  position: relative;
  width: 450px;
  height: 620px;
  border-radius: 8px;
  background: #1c1c1c;
  overflow: hidden;
  margin: auto;
  display: block;
}

.signup-box::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 450px;
  height: 620px;
  background: linear-gradient(0deg, transparent, #45f4ff, #45f4ff);
  transform-origin: bottom right;
  animation: animate 6s linear infinite;
}

.signup-box::after {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 450px;
  height: 620px;
  background: linear-gradient(0deg, transparent, #45f4ff, #45f4ff);
  transform-origin: bottom right;
  animation: animate 6s linear infinite;
  animation-delay: -3s;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.signup-form {
  position: absolute;
  inset: 2px;
  border-radius: 8px;
  background: #28292d;
  z-index: 10;
  padding: 50px 40px;
  display: flex;
  flex-direction: column;
}
.signup-form h2 {
  color: #45f3ff;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0, 1em;
}

.signup-inputBox {
  position: relative;
  width: 100%;
  margin-top: 35px;
}
.signup-inputBox input {
  position: relative;
  width: 100%;
  padding: 10px 9px 10px;
  background: transparent;
  border: none;
  outline: none;
  color: #23242a;
  font-size: 1em;
  letter-spacing: 0.05em;
  z-index: 10;
}

.signup-inputBox span {
  position: absolute;
  left: 0;
  padding: 10px 10px 10px;
  font-size: 1em;
  color: #8f8f8f;
  pointer-events: none;
  letter-spacing: 0.05em;
  transition: 0.5s;
}

.signup-inputBox input:valid ~ span,
.signup-inputBox input:focus ~ span {
  color: #45f3ff;
  transform: translateX(0px) translateY(-34px);
  font-size: 0.75em;
}

.signup-inputBox i {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #45f3ff;
  border-radius: 4px;
  transition: 0.5s;
  pointer-events: none;
  z-index: 9;
}

.signup-inputBox input:valid ~ i,
.signup-inputBox input:focus ~ i {
  height: 44px;
}

.signup-links {
  display: flex;
  justify-content: space-between;
}

.signup-links a,
.signup-links p {
  margin: 20px 0 10px 0;
  font-size: 0.8em;
  color: #45f3ff;
  text-decoration: none;
}

.signup-links a {
  text-decoration: underline;
}

.signup-box button {
  border: none;
  outline: none;
  background: #45f3ff;
  padding: 10px 20px;
  width: auto;
  margin: 10px auto;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
}

.signup-box button:active {
  opacity: 0.6;
}
</style>
