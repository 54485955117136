import { createStore } from "vuex";

// Create a new store instance.
const store = createStore({
  state() {
    return {
      user: {},
    };
  },
  mutations: {
    setUser(state, user) {
      state.user = { email: user.email, is_admin: user.is_admin };
    },
  },
});

export default store;
