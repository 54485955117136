<template>
  <div class="section">
    <div class="profile-card">
      <!-- =========================================== -->
      <!-- When User login then profile user info show  -->
      <!-- =========================================== -->

      <!-- <div class="profile-card-info">
        <p><span>User Name:</span> user123</p>
        <p><span>User Email:</span> user123@gmail.com</p>
      </div> -->

      <!-- =========================================== -->
      <!-- When Admin login then profile admin info show  -->
      <!-- =========================================== -->

      <div class="profile-card-info">
        <p><span>Email:</span> {{ email }}</p>
        <p><span>First Name:</span> {{ first_name }}</p>
        <p><span>Last Name:</span> {{ last_name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../services/api.service";
export default {
  name: "ProfilePage",
  data() {
    return {
      email: "",
      first_name: "",
      last_name: "",
    };
  },
  created() {
    ApiService.get("/me")
      .then((response) => {
        this.email = response.data.email;
        this.first_name = response.data.first_name;
        this.last_name = response.data.last_name;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style>
.section {
  max-width: 1250px;
  width: 100%;
  margin: 10rem auto;
}

.profile-card {
  margin: 5rem auto;
  display: block;
  max-width: 450px;
  width: 100%;
  padding: 3rem;
  border: 2px solid #006d77;
}

.profile-card-info {
  text-align: start;
}

.profile-card-info p {
  font-size: 18px;
}

.profile-card-info p span {
  font-weight: 600;
  color: #006d77;
}
</style>
