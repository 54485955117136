<template>
  <div class="home">
    <ToolHome />
  </div>
</template>

<script>
import ToolHome from "../components/ToolHome.vue";

export default {
  name: "HomeView",
  components: {
    ToolHome,
  },
};
</script>
