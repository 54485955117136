<template>
  <div class="section">
    <div class="forgot__box">
      <form class="forgot__form">
        <h2>Forgot Password</h2>

        <div class="forgot__inputBox">
          <input type="password" required />
          <span>Password</span>
          <i></i>
        </div>

        <div class="forgot__inputBox">
          <input type="password" required />
          <span>Confirm Password</span>
          <i></i>
        </div>

        <button>Submit</button>
      </form>
    </div>
  </div>
</template>

<style>
.section {
  max-width: 1250px;
  width: 100%;
  margin: 10rem auto;
}

.forgot__box {
  position: relative;
  width: 450px;
  height: 400px;
  border-radius: 8px;
  background: #1c1c1c;
  overflow: hidden;
  margin: auto;
  display: block;
  padding-bottom: 3rem;
}

.forgot__box::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 450px;
  height: 400px;
  background: linear-gradient(0deg, transparent, #45f4ff, #45f4ff);
  transform-origin: bottom right;
  animation: animate 6s linear infinite;
}

.forgot__box::after {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 450px;
  height: 400px;
  background: linear-gradient(0deg, transparent, #45f4ff, #45f4ff);
  transform-origin: bottom right;
  animation: animate 6s linear infinite;
  animation-delay: -3s;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.forgot__form {
  position: absolute;
  inset: 2px;
  border-radius: 8px;
  background: #28292d;
  z-index: 10;
  padding: 50px 40px;
  display: flex;
  flex-direction: column;
}
.forgot__form h2 {
  color: #45f3ff;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0, 1em;
}

.forgot__inputBox {
  position: relative;
  width: 100%;
  margin-top: 35px;
}
.forgot__inputBox input {
  position: relative;
  width: 100%;
  padding: 10px 9px 10px;
  background: transparent;
  border: none;
  outline: none;
  color: #23242a;
  font-size: 1em;
  letter-spacing: 0.05em;
  z-index: 10;
}

.forgot__inputBox span {
  position: absolute;
  left: 0;
  padding: 10px 10px 10px;
  font-size: 1em;
  color: #8f8f8f;
  pointer-events: none;
  letter-spacing: 0.05em;
  transition: 0.5s;
}

.forgot__inputBox input:valid ~ span,
.forgot__inputBox input:focus ~ span {
  color: #45f3ff;
  transform: translateX(0px) translateY(-34px);
  font-size: 0.75em;
}

.forgot__inputBox i {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #45f3ff;
  border-radius: 4px;
  transition: 0.5s;
  pointer-events: none;
  z-index: 9;
}

.forgot__inputBox input:valid ~ i,
.forgot__inputBox input:focus ~ i {
  height: 44px;
}

.forgot__links {
  display: flex;
  justify-content: center;
}

.forgot__links a,
.forgot__links p {
  margin: 20px 0 10px 10px;
  font-size: 0.8em;
  color: #45f3ff;
  text-decoration: none;
}

.forgot__links__forgot {
  margin: 30px 0 10px 0;
  text-align: right;
}

.forgot__links__forgot a {
  font-size: 0.8em;
  color: #45f3ff;
  text-decoration: none;
}

.forgot__links a {
  text-decoration: underline;
}

.forgot__box button {
  border: none;
  outline: none;
  background: #45f3ff;
  padding: 10px 20px;
  width: auto;
  margin: 50px auto 20px auto;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
}

.forgot__box button:active {
  opacity: 0.6;
}
</style>
