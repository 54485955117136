<template>
  <div class="home">
    <ProFile />
  </div>
</template>

<script>
// @ is an alias to /src
import ProFile from "../components/ProFile.vue";

export default {
  name: "ProFileView",
  components: {
    ProFile,
  },
};
</script>
