import { createApp } from "vue";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// initialize services
import ApiService from "./services/api.service.js";
import * as JwtService from "./services/jwt.service.js";

router.beforeEach((to, from, next) => {
  ApiService.init();
  if (to.matched.some((record) => record.meta.requireAuth)) {
    if (!JwtService.getToken()) {
      next({
        name: "login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      ApiService.get("/me")
        .then((response) => {
          store.commit("setUser", response.data);
          next();
        })
        .catch((error) => {
          console.log(error);
          JwtService.destroyToken();
          next({ name: "login" });
        });
      // next();
    }
  }

  if (to.name === "login") {
    if (JwtService.getToken()) {
      next({ name: "home" });
    }
  }
  next();
  Vue.nextTick(() => {
    document.title = `${to.meta.title}`;
  });
});

//sweetalert2
import Swal from "sweetalert2";

window.Swal = require("sweetalert2");
//Toast
window.Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

createApp(App).use(router).use(store).mount("#app");
