<template>
  <div class="home">
    <ForgotPassword />
  </div>
</template>

<script>
// @ is an alias to /src
import ForgotPassword from "../components/ForgotPassword.vue";

export default {
  name: "SignUpView",
  components: {
    ForgotPassword,
  },
};
</script>
