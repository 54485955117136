<template>
  <div class="section">
    <div class="table-row">
      <div class="user-add-btn">
        <button data-toggle="modal" data-target="#addUserShow">Add User</button>
      </div>

      <div class="table__container">
        <div class="show-entries-btn">
          <!-- <p>Show</p>
          <select
            class="form-select show-entries-select-btn"
            aria-label="Default select example"
          >
            <option value="1">100</option>
            <option value="2">500</option>
            <option value="3">700</option>
            <option value="4">All</option>
          </select>
          <p>entries</p> -->
        </div>

        <div class="group">
          <input
            v-model="search_profile"
            @keyup="searchProfileFromDB()"
            type="text"
            required
          />
          <span class="highlight"></span>
          <span class="bar"></span>
          <label>Search User</label>
        </div>
      </div>
    </div>

    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th scope="col">No</th>
          <th scope="col">User Name</th>
          <th scope="col">Email</th>
          <th scope="col">First Name</th>
          <th scope="col">Last Name</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user, index) in users" :key="index">
          <th scope="row">{{ index + 1 }}</th>
          <td>{{ user.email }}</td>
          <td>{{ user.first_name }}</td>
          <td>{{ user.last_name }}</td>
          <td class="table-actions-btn">
            <!-- <button data-toggle="modal" data-target="#editUserShow">
              <i class="fa-solid fa-pen-to-square"></i>
            </button> -->
            <button @click="deleteUser(user.id)">
              <i class="fa-solid fa-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <div>
      <!-- <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-end">
          <li class="page-item disabled">
            <a class="page-link" href="#" tabindex="-1">Previous</a>
          </li>
          <li class="page-item"><a class="page-link" href="#">1</a></li>
          <li class="page-item"><a class="page-link" href="#">2</a></li>
          <li class="page-item"><a class="page-link" href="#">3</a></li>
          <li class="page-item">
            <a class="page-link" href="#">Next</a>
          </li>
        </ul>
      </nav> -->
    </div>
  </div>

  <!-- ============================= -->
  <!-- User Add button Modal Area-->
  <!-- ============================ -->

  <div
    class="modal fade"
    id="addUserShow"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">User Add</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body modal__body__edit">
          <div class="group">
            <input v-model="add_user.first_name" type="text" required />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label>First Name</label>
          </div>

          <div class="group">
            <input v-model="add_user.last_name" type="text" required />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label>Last Name</label>
          </div>

          <div class="group">
            <input v-model="add_user.email" type="email" required />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label>Email</label>
          </div>

          <div class="group">
            <input v-model="add_user.password" type="password" required />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label>Password</label>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button
            @click.prevent="submitAdd()"
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../services/api.service";
export default {
  name: "DashboardPage",
  data() {
    return {
      search_profile: null,
      backup_links: "",
      users: [],
      add_user: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
      },
    };
  },
  mounted() {
    this.get_all_users();
  },

  methods: {
    filterStringsByLastPathComponent(strings, substring) {
      return strings.filter(function (string) {
        var lastPathComponent = string.email;
        return lastPathComponent.includes(substring);
      });
    },
    searchProfileFromDB() {
      if (this.search_profile !== null && this.search_profile.length !== 0) {
        if (this.backup_links.length === 0) {
          this.backup_links = this.users;
        }
        this.links = this.backup_links;
        let filteredStrings = this.filterStringsByLastPathComponent(
          this.users,
          this.search_profile
        );
        this.users = filteredStrings;
      } else {
        this.users = this.backup_links;
        this.backup_links = [];
        this.search_profile = null;
      }
    },
    deleteUser(user_id) {
      ApiService.delete(`/delete-user/${user_id}`)
        .then((res) => {
          console.log(res);
          this.get_all_users();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    get_all_users() {
      ApiService.get("/all-users")
        .then((res) => {
          this.users = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitAdd() {
      ApiService.post("/users/", this.add_user)
        .then((res) => {
          console.log(res);
          this.add_user = {
            first_name: "",
            last_name: "",
            email: "",
            password: "",
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.section {
  max-width: 1250px;
  width: 100%;
  margin: 12rem auto 10rem auto;
}

.table-actions-btn button {
  margin: 0.1rem 1rem;

  border-radius: 6px;
}

.table-actions-btn button:nth-child(1) {
  border: 1px solid #254117;
}

.table-actions-btn button:nth-child(1):active {
  opacity: 0.6;
}

.table-actions-btn button:nth-child(1) i {
  color: #254117;
  font-size: 16px;
}

.table-actions-btn button:nth-child(2) {
  border: 1px solid #ff0000;
}

.table-actions-btn button:nth-child(2):active {
  opacity: 0.6;
}

.table-actions-btn button:nth-child(2) i {
  color: #ff0000;
  font-size: 16px;
}

.show-entries-btn {
  display: flex;
  justify-content: start;
  align-items: start;
  color: #000000;
  font-weight: 500;
  margin: 0rem 2rem;
}
.show-entries-btn p {
  margin: 5px 5px 0px 5px;
}

.show-entries-select-btn {
  width: 100px;
  height: 35px;
}

.group {
  position: relative;
  margin: 45px 55px 45px 0px;
}
.group input {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 350px;
  border: none;
  border-bottom: 1px solid #757575;
}
.group input:focus {
  outline: none;
}

/* LABEL ======================================= */
.group label {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
.group input:focus ~ label,
.group input:valid ~ label {
  top: -20px;
  font-size: 14px;
  color: #006d77;
}

/* BOTTOM BARS ================================= */
.group .bar {
  position: relative;
  display: block;
  width: 350px;
}
.group .bar:before,
.group .bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #006d77;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.group .bar:before {
  left: 50%;
}
.group .bar:after {
  right: 50%;
}

/* active state */
.group input:focus ~ .bar:before,
.group input:focus ~ .bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* active state */
.group input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from {
    background: #006d77;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-moz-keyframes inputHighlighter {
  from {
    background: #006d77;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes inputHighlighter {
  from {
    background: #006d77;
  }
  to {
    width: 0;
    background: transparent;
  }
}

.table__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
}

.table-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.user-add-btn {
  margin: 1rem 1rem;
}
.user-add-btn button {
  border: 2px solid #03a9f4;
  color: #03a9f4;
  background: #ffffff;
  padding: 5px 20px;
  border-radius: 8px;
}

.user-add-btn button:hover {
  background: #03a9f4;
  color: #ffffff;
}

.modal__body__edit {
  text-align: center;
  margin: 0px auto;
  width: 80%;
}
</style>
