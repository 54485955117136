<template>
  <nav
    class="navbar navbar-expand-lg navbar-dark navbar-row fixed-top"
    style="background-color: #006d77"
  >
    <div class="container-fluid container-section">
      <router-link to="/" :class="`navbar-brand fw-bold`"
        >PURLS</router-link
      >
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <router-link to="/" :class="`nav-link active`">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/profile" :class="`nav-link`">{{email}}</router-link>
          </li>
          <!-- ============================================================================= -->
          <!-- When admin login then show dashboard button. When user login don't show dashboard button -->
          <!-- ============================================================================= -->
          <li class="nav-item" v-if="is_admin">
            <router-link to="/dashboard" :class="`nav-link`"
              >Dashboard</router-link
            >
          </li>

          <li class="nav-item" v-if="email">
            <button class="nav-link" @click="logoutBtn()">Logout</button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import jwtService from "../services/jwt.service";
import NotificationService from "../services/notifications.service";
export default {
  name: "NavBar",
  computed: {
    email() {
      return this.$store.state.user.email;
    },
    is_admin() {
      return this.$store.state.user.is_admin;
    },
  },
  methods: {
    logoutBtn() {
      jwtService.destroyToken();
      NotificationService.success("logout");
      location.href = "/";
    },
  },
};
</script>

<style scoped>
.navbar .navbar-nav .nav-link {
  color: #fff;
  font-size: 1.1rem;
  margin: 1rem auto !important;
}

.container-section {
  max-width: 1250px;
  width: 100%;
  margin: auto;
}

.navbar-brand {
  font-size: 30px;
}

@media screen and (min-width: 992px) {
  .navbar .navbar-nav .nav-link {
    padding: 0rem 0.5rem;
  }
  .navbar .navbar-nav .nav-item:not(:last-child) .nav-link {
    border-right: 3px solid #fff;
  }
}
</style>
