import axios from "axios";
import JwtService from "./jwt.service";

const ApiService = {
  init() {
    axios.defaults.withCredentials = true;
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
    if (JwtService.getToken()) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${JwtService.getToken()}`;
      // axios.defaults.headers.common["Content-Type"] = "application/json";
    } else {
      axios.defaults.headers.common["Authorization"] = null;
    }
  },

  get(resource, params) {
    return axios.get(`${resource}`, params);
  },

  post(resource, data, headers) {
    return axios.post(`${resource}`, data, headers);
  },

  update(resource, params) {
    return axios.put(`${resource}`, params);
  },

  delete(resource) {
    return axios.delete(resource);
  },
};

export default ApiService;
